import Vue from "vue"
import { STOCKIN_TYPE } from '../../../../../../mixins/enum'

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
export default {
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      stockin_type: STOCKIN_TYPE
    }
  },
  components: {},
  methods: {
    execute() {
      this.state = this.state? !this.state: this.state
      if (!this.state) {
        this.hideMenu()
      }
    },
    menuList() {
      this.state = !this.state
      if (this.state) {
        this.showMenu()
      } else {
        this.hideMenu()
      }
    },
    showMenu() {
      const el = this.$refs.menu
      el.classList.add('animation-menu-list')
    },
    hideMenu() {
      const el = this.$refs.menu
      el.classList.remove('animation-menu-list')
    },
    onselectmenu(target) {
      this.execute()
      this.$emit('selection', { 
        id: this.id,
        menu: target,
        status: true
      })
    }
  }
}